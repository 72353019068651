// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require("jquery")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap-datepicker")
require("vue.min.js")
require("swiper.min.js")
require("jquery.validate.js")
require("Chart.PieceLabel.min.js")
require("select2.min.js")
require("jquery-ui.min.js")
require("bootstrap.min.js")


var Promise = require('promise');

import { polyfill } from 'es6-promise'; polyfill();
import("../stylesheets/application");
import './bootstrap_custom.js';
import Chart from 'chart.js';
import 'chartjs-plugin-doughnutlabel/dist/chartjs-plugin-doughnutlabel.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

window.jQuery = $;
window.$ = $;

require("trix")
require("@rails/actiontext")


import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://ba1f864294b740c08fc4654df370091b@o448105.ingest.sentry.io/5428953",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  environment: "production",
  ignoreErrors: ['Non-Error promise rejection captured'],
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
}); 